.section__errorpage {
  min-height: 100vh;
  display: flex;
  place-items: center;
  justify-content: center;
  gap: 2rem;
  position: relative;
}
.section__errorpage-content {
  z-index: 1;
}

.error404 {
  font-size: 5rem;
  font-size: bolder;
}
.errorPageSvg {
  width: 50%;
  height: 50%;
  opacity: 0.5;
  object-fit: cover;
}

@media screen and (max-width: 980px) {
  .section__errorpage {
    min-height: 110vh;
    flex-direction: column;
  }
  .section__errorpage-content {
    text-align: center;
  }
  .section__errorpage-content .contact-button {
    margin: 15px auto;
  }
  .error404 {
    // font-size: 3.5rem;
    font-size: bolder;
  }
  .errorPageSvg {
    width: 100%;
    height: 100%;
  }
}

@media screen and (max-width: 450px) {
  .section__errorpage {
    min-height: 120vh;
    flex-direction: column;
  }
  .section__errorpage-content {
    text-align: center;
  }
  .error404 {
    // font-size: 2rem;
    font-size: bolder;
  }
  .errorPageSvg {
    width: 100%;
    height: 100%;
  }
}
