.about-description-skills {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-auto-rows: auto;
  grid-gap: 2rem;
  margin-top: 50px;
}

.skill-container {
  padding: 15px;
  background-color: var(--primary-bg);
  border-radius: 5px;
  box-shadow: var(--box-shadow);
  position: relative;
  display: grid;
  place-items: center;
  position: relative;
  transition: all 250ms;
  .skills-icon {
    font-size: 2.5rem;
    color: var(--color-tem);
  }
  span {
    display: block;
    font-size: 0.875rem;
    text-transform: capitalize;
    margin-top: 10px;
  }
}

.skill-container::after {
  content: '';
  width: 0%;
  height: 2px;
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--color-tem);
  transition: all 0.5s linear;
  border-radius: 5px;
  transition: all 0.3s linear;
  display: block;
}
.skill-container:hover::after {
  width: 100%;
  transform: translateY(-10px);
}

@media screen and (min-width: 2000px) {
  .about-description-skills {
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 2rem;
  }
}

@media screen and (max-width: 1900px) {
  .about-description-skills {
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 2rem;
  }
}

@media screen and (max-width: 1024px) {
  .about-description-skills {
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 2rem;
  }
}

@media screen and (max-width: 980px) {
  .about-description-skills {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media screen and (max-width: 768px) {
  .about-description-skills {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (max-width: 450px) {
  .about-description-skills {
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
  }
}
